import { useState, useEffect } from 'react';
import workbookStorage from '@/services/storage/workbooks';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'common: hooks: useLoadWorkbookFile:';
// Download the workbook or spreadsheet
// and ensure it's usable as a workbook
export default function useLoadWorkbookFile(storage, widget, workbook) {
  const {
    0: error,
    1: setError
  } = useState(null);
  const {
    0: status,
    1: setStatus
  } = useState('loading');
  const {
    0: workbookFile,
    1: setWorkbookFile
  } = useState([]); // Request Download URL

  const {
    status: downloadStatus,
    error: downloadError,
    data: downloadUrl
  } = useDownloadWorkbookUrl(storage, widget, workbook);
  useEffect(() => {
    if (!downloadUrl) return;

    (async function () {
      // Present download URL error to consumers
      if (downloadError) {
        const wrappedError = createWrappedError(`${PREFIX} useLoadWorkbookFile: download URL failed`, downloadError);
        setError(wrappedError);
        setStatus('failure');
        setWorkbookFile([]);
      }

      if (downloadStatus !== 'success') return;

      try {
        // Download workbook/spreadsheet
        // JSON file from storage
        const data = await workbookStorage.downloadWorkbook(downloadUrl);
        setWorkbookFile(data);
        setStatus('success');
      } catch (err) {
        const wrappedError = createWrappedError(`${PREFIX} useLoadWorkbookFile: failed to load workbook from "${downloadUrl}"`, err);
        setError(wrappedError);
        setStatus('failure');
        setWorkbookFile([]);
      }
    })();
  }, [downloadUrl, downloadStatus, downloadError, workbook === null || workbook === void 0 ? void 0 : workbook.syncedAt]);
  return {
    error,
    status,
    data: workbookFile,
    url: downloadUrl
  };
}
// Request a download URL from Firebase Storage
// using a reporting widget, failing when
// URL is not discoverable
export function useDownloadWorkbookUrl(storage, widget, workbook) {
  const {
    0: result,
    1: setResult
  } = useState({
    status: 'loading',
    error: null,
    data: ''
  });
  useEffect(() => {
    if (!widget) return; // Workbook File Preference Order:
    // 1. Workbook's file (by editors)
    // 2. Widgets' workbook file [PREFERED] (used by snippet)
    // 3. Widgets' spreadsheet file [DEPRECATED] (used by snippet)

    const storagePath = (workbook === null || workbook === void 0 ? void 0 : workbook.fileRef) || widget.workbookFileRef || widget.spreadsheet;

    if (!storagePath) {
      const err = Error(`${PREFIX} useDownloadWorkbookUrl: given non-reporting widget "${widget.id}"`);
      setResult({
        status: 'failed',
        error: err,
        data: ''
      });
      return;
    }

    workbookStorage.getDownloadUrl(storage, storagePath).then(url => {
      setResult({
        data: url,
        status: 'success',
        error: null
      });
    }).catch(err => {
      const wrappedErr = Error(`${PREFIX} useDownloadWorkbookUrl: failed to download spreadsheet JSON at: "${storagePath}": ${err}`);
      setResult({
        error: wrappedErr,
        status: 'failure',
        data: ''
      });
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget]);
  return result;
}